if (
    typeof String.prototype.includes !== 'function' ||
    typeof String.prototype.padStart !== 'function' ||
    typeof String.prototype.startsWith !== 'function' ||
    typeof Object.values !== 'function'
) {
    require('core-js');
}

if (typeof fetch !== 'function') require('whatwg-fetch');
